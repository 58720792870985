<template>
  <!-- 角色管理 -->
  <div class="wrapper">
    <el-form inline class="search-header" :model="searchData" label-width="">
      <el-form-item label="角色名称">
        <el-input v-model="searchData.roleName" placeholder="请输入角色名称" size="small"></el-input>
      </el-form-item>
      <el-form-item label="" style="text-align:right">
        <el-button type="primary" icon="el-icon-search" size="small" @click="getRoleList">查询</el-button>
        <el-button size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <div class="btn-group">
      <div></div>
      <div>
        <el-button v-show="isShowBtn('RoleAdd')" type="primary" size="small" icon="el-icon-plus" @click="$router.push({ name: 'RoleAdd' })">添加</el-button>
      </div>
    </div>
    <tp-table :tableData="roleList" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="page_size" />

    <!-- 授权 -->
    <el-drawer ref="drawer" title="角色权限" :visible.sync="drawer" :show-close="false">
      <el-card>
        <div slot="header">
          <el-button type="primary" size="small" @click="saveAuthority">保存设置</el-button>
        </div>
        <div>
          <el-tree
            ref="tree"
            highlight-current
            :data="menuTree"
            show-checkbox
            node-key="id"
            default-expand-all
            :default-checked-keys="grantMenuObj.menuIdList"
            :props="defaultProps"
            @check="checkChange"
          >
          </el-tree>
        </div>
      </el-card>
    </el-drawer>
  </div>
</template>

<script>
import { getRoleList, removeRole, getMenusTree, grantMenu, getRoleMenu, getRoleDetail, getModuleList } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '角色',
    prop: 'roleName',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['roleName']}>
          {row['roleName']}
        </p>
      )
    }
  },
  // {
  //   label: '状态',
  //   prop: 'status',
  //   customRender(h, row) {
  //     return (
  //       // <el-switch v-model={row['status']} active-value={1} inactive-value={0} active-color="#409eff" inactive-color="#dcdfe6"></el-switch>
  //       <p class="one-line" title={row['statusName']}>
  //         <el-tag type="" v-show={row['status'] == 1}>
  //           启用
  //         </el-tag>
  //         <el-tag type="danger" v-show={row['status'] == 0}>
  //           禁用
  //         </el-tag>
  //       </p>
  //     )
  //   }
  // },
  {
    label: '描述',
    prop: 'remark',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['remark']}>
          {row['remark']}
        </p>
      )
    }
  },
  {
    label: '创建时间',
    prop: 'createTime',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['createTime']}>
          {row['createTime']}
        </p>
      )
    }
  },
  {
    label: '操作',
    width: '300',
    customRender(h, row) {
      return (
        <div>
          <el-link
            v-show={this.isShowBtn('RoleEdit')}
            underline={false}
            style="margin-right:20px"
            type="primary"
            onClick={() => this.$router.push({ name: 'RoleEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link
            v-show={this.isShowBtn('RoleAuthMenu')}
            underline={false}
            style="margin-right:20px"
            type="primary"
            onClick={() => this.showMenu(row['id'])}
          >
            授权菜单
          </el-link>
          <el-popconfirm title={`确定删除吗`} onConfirm={() => this.goRemove(row['id'])}>
            <el-link
              v-show={this.isShowBtn('RoleDel')}
              underline={false}
              type="primary"
              slot="reference"
            >
              删除
            </el-link>
          </el-popconfirm>
        </div>
      )
    }
  }
]
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      menuModule: 'admin',
      drawer: false,
      roleList: [],
      columns,
      total: 0,
      currentPage: 1,
      page_size: 20,
      defaultProps: {
        children: 'children',
        label: 'menuName',
        value: 'id'
      },
      menuTree: [],
      // 授权信息
      grantMenuObj: {
        id: null,
        menuIdList: []
      },
      searchData: {
        roleName: ''
      },
      allKeys: []
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getRoleList()
    }
  },
  mounted() {
    this.getRoleList()
    // this.getModuleList()
    // this.apiQueryMenuTree()
  },

  methods: {
    async getRoleList() {
      let params = { roleName: this.searchData.roleName, page: this.currentPage, pageSize: this.page_size }
      let res = await getRoleList(params)
      this.roleList = res?.list || []
      this.total = res?.total || 0
    },

    async apiQueryMenuTree() {
      this.menuTree = await getMenusTree()
    },
    // 复选框被点击时的回调
    checkChange() {
      // 获取当前被选中的菜单id数组
      this.grantMenuObj.menuIdList = this.$refs.tree.getCheckedKeys()
      this.allKeys = this.$refs.tree.getCheckedNodes(false, true).map(item => item.id)
    },
    // 保存菜单授权
    async saveAuthority() {
      let data = { id: this.grantMenuObj.id, menuIdList: this.allKeys }
      await grantMenu(data).then(() => {
        this.$message.success('授权成功')
        this.drawer = false
        this.getRoleList()
      })
    },
    goAdd() {
      this.$router.push({ name: 'RoleDetail' })
    },
    goEdit(id) {
      this.$router.push({ name: 'RoleDetail', params: { id: id || undefined } })
    },
    async goRemove(id) {
      await removeRole({ id }).then(() => {
        this.$message.success('删除成功')
        this.getRoleList()
      })
    },
    async showMenu(id) {
      this.apiQueryMenuTree()
      this.drawer = true
      this.grantMenuObj.id = id
      const { menuList } = await getRoleDetail({ id })
      this.grantMenuObj.menuIdList = this.fileterMenuIds(menuList).filter(item => !this.$refs.tree.getNode(item).childNodes.length)
    },
    fileterMenuIds(list) {
      let menuIds = []
      list.forEach(item => {
        if (item.children && item.children.length > 0) {
          menuIds = this.fileterMenuIds(item.children)
        } else {
          // if (item.hasPermit == 1) {
          //   menuIds.push(item.id)
          // }
          menuIds.push(item.id)
        }
      })
      return menuIds
    },
    async getModuleList() {
      this.MENU_MODULE = await getModuleList()
    },
    handleClick() {
      this.getRoleList()
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getRoleList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-width: 800px;
  .search-header /deep/ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .arrow {
      text-align: right;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
    .el-form-item {
      width: 20%;
      min-width: 300px;
      margin-bottom: 0;
      margin-right: 0;
    }
  }
  .el-divider {
    margin: 20px 0;
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
