export default {

    // 客户订单
    customer_order_list: 'CustomerOrderList', // 查看订单
    customer_add_remark: 'CustomerAddRemark', // 添加备注
    customer_remark_list: 'CustomerRemarkList', // 备注列表

    // 订单管理
    order_assign_manager: 'orderAssignManager', // 分配客户经理
    order_delivery: 'orderDelivery', //发货时间
    bill_list: 'billList', // 账单明细

    // 账单管理
    bill_export: 'billExport', // 账单导出

    // 财务管理
    finance_bill_export: 'financeBillExport', // 财务明细导出
    finance_bill_del: 'financeBillDel', // 财务明细删除

    // 管理员
    user_add: 'UserAdd', // 添加
    user_edit: 'UserEdit', // 编辑
    user_del: 'UserDel', // 删除

    // 角色管理
    role_add: 'RoleAdd',
    role_edit: 'RoleEdit',
    role_auth_menu: 'RoleAuthMenu',
    role_del: 'RoleDel',

    // 菜单管理
    menu_add: 'MenuAdd',
    menu_edit: 'MenuEdit',
    menu_del: 'MenuDel',

}